<template>
  <svg viewBox="0 0 32 32">
    <path
      d="M18.6668 29.9998L14.9335 25.3332H8.00016C7.64654 25.3332 7.3074 25.1927 7.05735 24.9426C6.80731 24.6926 6.66683 24.3535 6.66683 23.9998V9.4705C6.66683 9.11688 6.80731 8.77774 7.05735 8.52769C7.3074 8.27765 7.64654 8.13717 8.00016 8.13717H29.3335C29.6871 8.13717 30.0263 8.27765 30.2763 8.52769C30.5264 8.77774 30.6668 9.11688 30.6668 9.4705V23.9998C30.6668 24.3535 30.5264 24.6926 30.2763 24.9426C30.0263 25.1927 29.6871 25.3332 29.3335 25.3332H22.4002L18.6668 29.9998ZM21.1188 22.6665H28.0002V10.8038H9.3335V22.6665H16.2148L18.6668 25.7305L21.1188 22.6665ZM2.66683 2.6665H25.3335V5.33317H4.00016V19.9998H1.3335V3.99984C1.3335 3.64622 1.47397 3.30708 1.72402 3.05703C1.97407 2.80698 2.31321 2.6665 2.66683 2.6665V2.6665Z"
    />
  </svg>
</template>

<script>
export default {
  name: "CommentsIcon",
};
</script>
